import {
  Box,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import allReviews from "./data/all";

const AllReviews = () => {
  const theme = useTheme();
  return (
    <Box component="section" paddingY={2}>
      {allReviews.map((item, index) => (
        <Card key={index} sx={{ borderRadius: 5, marginBottom: 2 }}>
          <CardContent>
            <Typography
              component="p"
              sx={{
                color: theme.palette.text.primary,
                fontSize: 18,
                fontWeight: 500,
                lineHeight: 1.7,
                marginBottom: 2,
              }}
            >
              {item.feedback}
            </Typography>
            <Box width={1}>
              <ListItem disableGutters>
                <ListItemText
                  primaryTypographyProps={{
                    color: "gray",
                  }}
                  primary={`- ${item.name}`}
                />
              </ListItem>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default AllReviews;
