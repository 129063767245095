import { Box, Card, Typography } from "@mui/material";
import Form from "./Form";

export const Mini = () => {
  return (
    <Box
      padding={{ xs: 3, sm: 3 }}
      width={"100%"}
      component={Card}
      borderRadius={2}
      boxShadow={4}
      aria-labelledby="contact-section-title"
    >
      <Typography
        id="contact-section-title"
        align="center"
        variant="h5"
        color="textSecondary"
        gutterBottom
        sx={{
          fontWeight: 700,
          color: "green",
          mb: 3,
        }}
      >
        Contact us for a free quote!
      </Typography>
      <Form />
    </Box>
  );
};

export default Mini;
