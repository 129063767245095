import { Box, Container, useTheme } from "@mui/material";
import Contact from "../containers/Contact";
import Footer from "../containers/Footer";
import Hero from "../containers/Hero";
import Reviews from "../containers/Reviews";
import Services from "../containers/Services";
import Why from "../containers/Why";
// import ContactForm from "../containers/Contact/Form";

const Home = () => {
  const theme = useTheme();
  return (
    <Box overflow="hidden">
      <section>
        <Box
          sx={{
            backgroundImage:
              'url("/banner.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&:after": {
              position: "absolute",
              content: '" "',
              width: "100%",
              height: "100%",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1,
              background: theme.palette.common.black,
              opacity: 0.5,
            },
          }}
          position={"relative"}
        >
          <Hero />
        </Box>
      </section>

      <section id="services">
        <Box sx={{ background: "#FFFFFF" }}>
          <Container sx={{ paddingBottom: 5 }}>
            <Services />
          </Container>
        </Box>
      </section>
      <section id="about">
        <Box sx={{ background: "rgb(243, 246, 255)" }}>
          <Container sx={{ paddingBottom: 5, paddingTop: 3 }}>
            <Why />
          </Container>
        </Box>
      </section>
      <section id="reviews">
        <Box sx={{ paddingBottom: 5, background: "#fbfcff" }}>
          <Reviews />
        </Box>
      </section >

      <section id="contact">
        <Box sx={{ background: "#222B45" }}>
          <Container
            sx={{
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <Contact />
          </Container>
        </Box>
      </section>

      <footer>
        <Footer />
        {/* <CookieBanner /> */}
      </footer>
    </Box >
  );
};

export default Home;
