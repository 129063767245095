"use client";

import { PaletteOptions } from "@mui/material";
import { dark as darkBlue, light as lightBlue } from "./palette-blue";
import { dark as darkGreen, light as lightGreen } from "./palette-green";
import { PaletteType, ThemeMode } from ".";
// import { light as lightIndigo, dark as darkIndigo } from "./palette--indigo";
// import { light as lightPink, dark as darkPink } from "./palette--pink";
// import { light as lightOrange, dark as darkOrange } from "./palette--orange";



const palette = (themeMode: ThemeMode, paletteType: PaletteType): PaletteOptions => {
  if (paletteType === "blue") {
    return themeMode === "dark" ? darkBlue : lightBlue;
  } else if (paletteType === "indigo") {
    //     return themeMode === "dark" ? darkIndigo : lightIndigo;
    //   } else if (paletteType === "pink") {
    //     return themeMode === "dark" ? darkPink : lightPink;
    //   } else if (paletteType === "orange") {
    //     return themeMode === "dark" ? darkOrange : lightOrange;
  }
  return themeMode === "dark" ? darkGreen : lightGreen;
};

export default palette;
