import { Review } from "..";

const allReviews: Review[] = [
  {
    name: "Linda",
    feedback:
      "Thanks GGB. We absolutely love the ADU addition and everything you and you team did. Kathy is finally very settled in and so happy. ❤️ It’s a new beginning for both of us.",
  },
  {
    name: "Jonni P.",
    feedback:
      "GGB promptly responded when others didn't. Their large team started work within weeks, executing our design effectively. They handled setbacks professionally, reducing our stress. We're grateful and would hire them again!",
  },
  {
    name: "Madeleine B.",
    feedback:
      "Good Guy Builders did a fantastic job on my bathroom remodel, so I hired them for a major kitchen upgrade. My wishlist included a multifunctional kitchen, and they delivered flawlessly. Good Guy Builders and their skilled team managed everything efficiently, providing daily updates and photos. The project stayed on budget, finished ahead of schedule, and the results are amazing.",
  },
  {
    name: "Damon S.",
    feedback:
      "Good Guy Builders did an excellent job on our ADU garage addition, helping with planning and permitting. The quality and speed of work were impressive, but the best part was the communication. We received daily updates and weekly budget reports, always knowing what was happening on-site. The project finished on time and within budget. Highly recommend Good Guy Builders.",
  },
  {
    name: "Steph T.",
    feedback:
      "Good Guy Builders truly live up to their name. Their team is honest, respectful, and greatly enhanced our master bathroom renovation. They turned our vague ideas into reality with a methodical and thoughtful approach, exceeding our expectations.",
  },
  {
    name: "Ryan P. ",
    feedback:
      "We hired GGB for a commercial renovation in a preschool. They delivered high-quality results within a tight timeline and an open program. Their efficient and communicative management kept us informed throughout. Compared to other contractors we've worked with, Good Guy Builders truly stand out as professionals who prioritize the client's best interests.",
  },
  {
    name: "Steph T.",
    feedback:
      "We are impressed day after day with Good Guy Builders and their talented team. Their professionalism, craftsmanship, expertise, and passion are truly brilliant. The pictures speak volumes about their work. We're fortunate to be partnering with such extraordinary professionals. Thanks to Steve for finding them on Nextdoor.com. We greatly appreciate their detailed and thorough approach, helpful solutions, and innovative thinking. A rare gem in the industry!",
  },
  {
    name: "Mad. B.",
    feedback:
      "We used Good Guy Builders to remodel our master bathroom in our Napa vacation home and couldn't be happier with the process and results. Despite the challenge of managing a remodel from afar, their expertise and transparency in time and costs put us at ease. Their team efficiently managed and drove the project, even assisting with the design. Communication was excellent with daily updates and photos. Professional, trustworthy, and high-quality work—highly recommend Good Guy Builders.",
  },
  {
    name: "Brenda M.",
    feedback:
      "Living among the Redwoods, our deck and basement suffered severe damage from rot and pests over the years. After seeking bids from several contractors, we were most impressed by the professionalism and 'can-do' attitude of Good Guy Builders. From the start, the Service Agreement terms were satisfactory, and we received daily progress reports throughout the project. The transformation has been unbelievable. John and Cliff, the builders, created beautiful new walls, windows, ceilings, stairs, decks, railings, and gates. Our basement is now livable with stunning views of the Redwood forest, and the fully rebuilt upper deck is attractive, classy, and spacious. We now feel safe and proud thanks to the masterful work accomplished by Good Guy Builders.",
  },
  {
    name: "Steven N.",
    feedback:
      "Good Guy Builders did a great job helping us renovate our kitchen, bedroom, and deck. They are knowledgeable on home construction, and we will hire them again.",
  },
  {
    name: "Douglas M.",
    feedback:
      "What can I say about Good Guy Builders that hasn't been said already? While I've never been a consumer of their services, I've worked alongside them as a design consultant on several high-end projects in Napa. Working with Good Guy Builders is unlike working with any other contractor. They are the upper echelon of professionals, and most other contractors should strive to reach their level. Good Guy Builders are among the most responsive, transparent, clear-communicating, involved, collaborative, accountable, honest, and creative builders I've ever worked with. They make my job easy with photos, sketches, and complete ideas in a succinct manner. They answer their phone, return calls and emails promptly, and provide a clear path forward in all situations. I only wish we had a 'Good Guy Builders' in my area, as I would recommend them to all my clients and would hire them myself without hesitation or concern. For the best contractor experience, hire Good Guy Builders.",
  },
  {
    name: "Sarada O.",
    feedback:
      "My husband and I were in a horrible car accident, and I realized I would be in a wheelchair for months with our home filled with obstacles. While in the hospital, I called several contractors for a bathroom remodel on short notice. Good Guy Builders answered the call on a weekend and were the first to listen to our situation and needs, agreeing to squeeze our project into their busy schedule. They were prompt, reliable, and professional. We received nightly emails with pictures and reviews of the progress, and they were always easy to reach by phone or text. We are happy with the job they completed, turning our raised bathtub into a beautiful walk-in (roll-in) shower. We highly recommend Good Guy Builders and are forever grateful for their empathy and willingness to make it all work.",
  },
  {
    name: "Maria, C.",
    feedback:
      "After getting three other bids, I decided on Good Guy Builders to fix/remodel my dad's house in Petaluma, and they did a great job! They worked with me to get the most done within our budget and kept in good contact with consistent updates while I was in Colorado. Despite some difficulties and miscommunications, the end result is just what I was hoping for. Thank you to the team of 'good guys.' I'm very happy to have chosen them; they were the best decision!",
  },
  {
    name: "Jacob N.",
    feedback:
      "Good Guy Builders were a huge help to me as I bought my first home. They performed a large renovation, including knocking down load-bearing walls, installing a new bathroom, and doing electrical and plumbing work. They also guided me through the financing and bureaucracy, meeting all deadlines. They went above and beyond and still keep in touch. I highly recommend them. Thank you, Good Guy Builders!",
  },
  {
    name: "Monica M.",
    feedback:
      "After hiring bad contractors who slowed my project by 4 months, I contacted Good Guy Builders. I normally don't write reviews, but they went above and beyond. They also helped me with subcontractors who tried cutting corners and spoke directly to their bosses. As an architect, it's rare to find a trustworthy builder who cares about quality, knows California building codes, and commits to finishing on time.",
  },
  {
    name: "Dave T.",
    feedback:
      "We contracted with Good Guy Builders after responding to their Craigslist advert. Our project was a 'down to bare walls' reconstruction of our dated kitchen, involving demo work, plumbing, heavy electrical, lighting design/installation, drywall install & texturing, painting, cabinet/drawer selection, purchase & installation, overall design of the kitchen, and all needed coordination. The team was courteous, professional, and meticulous. They exhibited cost awareness, saving us thousands of dollars while using high-quality materials. We are thrilled with the finished project and heartily recommend Good Guy Builders for projects of any scale. We would not hesitate to be enthusiastic references for future clients. Thanks, guys!",
  },
  {
    name: "Matt and Joanne S.",
    feedback:
      "Good Guy Builders are: Prompt. Punctual. Reliable. Excellent communicators. Skilled. Meticulous. Conscientious. Thorough. Creative. Reasonable. Practical. Adaptable. Good Guys. They patiently explained various approaches and came up with ideas and considerations that hadn't occurred to us. The end result is wonderful. We've already recommended them to a neighbor and are going to invite them back to install a new floor for us.",
  },
  {
    name: "Amy S.",
    feedback:
      "Wow, where to start? We feel as if we have adopted Good Guy Builders into our family since one project has led to the next. We hired them for a challenging renovation with unconventional style requests that took ingenuity to bring to life. The result is 100 times better than we imagined. We've since hired them for door installations, custom frames, retaining wall work, and more. Each project has been handled with quality and cost-consciousness. We recommend them to all our family and friends. The only downside is sometimes you have to wait to get on the schedule, but it's worth the wait.",
  },
  {
    name: "Melina R.",
    feedback:
      "TRULY... A breath of fresh air and godsend! In this cutthroat business, I've had more than my share of remodeling nightmares, and Good Guy Builders stepped in and took on a project that most would walk away from. I seriously don't know what I would have done without them! They are extremely knowledgeable in every trade, leave no detail to chance, and I've learned so much from working with them.",
  },
  {
    name: "Lisa T.",
    feedback:
      "Good Guy Builders were a blessing in disguise. After getting no response from 2 other cement contractors in Napa to help with my porch and foundation, I called an old high school friend who referred me to them. They were very punctual and communicated every aspect of the job. They even helped reroute my cable under the house at no charge. They are very educated and knowledgeable in construction and I would recommend them to anyone.",
  },
  {
    name: "Jim S.",
    feedback:
      "Good Guy Builders have done several jobs for us at our Napa home. They make sure the work is very specific upfront to avoid confusion and are big on setting completion dates. Their work is top-notch and their prices are very fair. Most recently, they re-tiled a bathroom, and it looks great. I highly recommend them.",
  },
];

export default allReviews;
