import { Review } from "..";

const reviews: Review[] = [
  {
    name: "Linda",
    feedback:
      "Thanks GGB. We absolutely love the ADU addition and everything you and you team did. Kathy is finally very settled in and so happy. ❤️ It’s a new beginning for both of us.",
  },
  {
    name: "Jonni P.",
    feedback:
      "GGB promptly responded when others didn't. Their large team started work within weeks, executing our design effectively. They handled setbacks professionally, reducing our stress. We're grateful and would hire them again!",
  },
  {
    name: "Madeleine B.",
    feedback:
      "Good Guy Builders did a fantastic job on my bathroom remodel, so I hired them for a major kitchen upgrade. My wishlist included a multifunctional kitchen, and they delivered flawlessly. Good Guy Builders and their skilled team managed everything efficiently, providing daily updates and photos. The project stayed on budget, finished ahead of schedule, and the results are amazing.",
  },
  {
    name: "Damon S.",
    feedback:
      "Good Guy Builders did an excellent job on our ADU garage addition, helping with planning and permitting. The quality and speed of work were impressive, but the best part was the communication. We received daily updates and weekly budget reports, always knowing what was happening on-site. The project finished on time and within budget. Highly recommend Good Guy Builders.",
  },
  {
    name: "Steph T.",
    feedback:
      "Good Guy Builders truly live up to their name. Their team is honest, respectful, and greatly enhanced our master bathroom renovation. They turned our vague ideas into reality with a methodical and thoughtful approach, exceeding our expectations.",
  },
  {
    name: "Ryan P. ",
    feedback:
      "We hired GGB for a commercial renovation in a preschool. They delivered high-quality results within a tight timeline and an open program. Their efficient and communicative management kept us informed throughout. Compared to other contractors we've worked with, Good Guy Builders truly stand out as professionals who prioritize the client's best interests.",
  },
];

export default reviews;
