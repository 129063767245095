import { Paper } from "@mui/material";
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./App.css";
// import MainLayout from "./layouts/Main";
import HomePage from "./pages/Home";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <Paper elevation={0}>
            {/* <MainLayout> */}{" "}
            {/* Uncomment this line to wrap the content in a layout */}
            <Outlet />
            {/* </MainLayout> */}
          </Paper>
        }
      >
        <Route
          path="/"
          element={
            <>
              <HomePage />
            </>
          }
        />
      </Route>
    </>
  )
);

export default router;