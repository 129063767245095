import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const Services = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box textAlign="center" my={4}>
        <Typography
          component="h2"
          variant="h4"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Our Services
        </Typography>
        <Typography variant="h5" fontWeight="700">
          Transforming Spaces with Excellence
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {[
          {
            media: "/kitchen.jpg",
            title: "Kitchen remodel",
            subtitle:
              "We help you design and build your dream home with the best quality materials and the best team.",
            alt: "Beautiful kitchen remodel with modern appliances and cabinetry.",
          },
          {
            media: "/bathroom.jpg",
            title: "Bathroom remodel",
            subtitle:
              "We help you design and build your dream home with the best quality materials and the best team.",
            alt: "Luxurious bathroom remodel with elegant fixtures and tiling.",
          },
          {
            media: "/home.jpg",
            title: "Whole Home Remodel",
            subtitle:
              "We help you design and build your dream home with the best quality materials and the best team.",
            alt: "Whole home remodel showcasing a beautiful living space.",
          },
          {
            media: "/outdoor.png",
            title: "Outdoor Spaces & Patios",
            subtitle:
              "We help you design and build your dream home with the best quality materials and the best team.",
            alt: "Stunning outdoor space with patio furniture and landscaping.",
          },
          {
            media: "adu.jpg",
            title: "ADUs & Pool Houses",
            subtitle:
              "We help you design and build your dream home with the best quality materials and the best team.",
            alt: "Elegant ADU and pool house design with modern architecture.",
          },
          {
            media: "/new-home.jpg",
            title: "New Home Construction",
            subtitle:
              "We help you design and build your dream home with the best quality materials and the best team.",
            alt: "New home construction with a modern and sleek design.",
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i} data-aos={"fade-up"}>
            <Box
              display={"block"}
              width={"100%"}
              height={"100%"}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Card
                component={Box}
                width={"100%"}
                height={"100%"}
                borderRadius={3}
                display={"flex"}
                flexDirection={"column"}
                elevation={2}
              >
                <CardMedia
                  component="img"
                  image={item.media}
                  title={item.title}
                  alt={item.alt}
                  sx={{
                    height: 240,
                    position: "relative",
                  }}
                />
                <Box justifyContent={"flex-end"} padding={2}>
                  <Typography textAlign="center" variant="h6" fontWeight={400}>
                    {item.title}
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
