import { AbcOutlined, DesignServices } from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";

interface NavDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
}

const sections = [
  { title: "Services", id: "services", icon: <DesignServices /> },
  { title: "About Us", id: "about", icon: <AbcOutlined /> },
  { title: "Reviews", id: "reviews", icon: <DesignServices /> },
  { title: "Contact Us", id: "contact", icon: <DesignServices /> },
];

const NavDrawer = ({ open, toggleDrawer }: NavDrawerProps) => {
  const handleSectionClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    toggleDrawer();
  };

  const theme = useTheme();
  return (
    <Drawer
      open={open}
      anchor="left"
      onClose={toggleDrawer}
      aria-label="navigation drawer"
    >
      <Container
        maxWidth="xs"
        sx={{
          padding: 2,
        }}
      >
        <Box
          marginBottom={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display={"flex"}
            alignItems="baseline"
            component="a"
            href="/"
            title="GGB"
            paddingY={1}
            sx={{
              alignItems: "center",
              textDecoration: "none",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 3,
              marginBottom: 1,
            }}
          >
            <img
              src="/logo-no-background.png"
              alt="Good Guy Builders logo"
              height={60}
            />
          </Box>
          <Typography
            gutterBottom
            color="primary.dark"
            sx={{
              fontWeight: 700,
              fontSize: "1.2rem",
            }}
            component="div"
          >
            GOOD GUY BUILDERS
          </Typography>
          <Divider variant="fullWidth" />
        </Box>
        <nav aria-label="main navigation">
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {sections.map((section) => (
              <ListItem
                button
                key={section.title}
                onClick={() => handleSectionClick(section.id)}
              >
                <ListItemText primary={section.title} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Container>
    </Drawer>
  );
};

export default NavDrawer;
