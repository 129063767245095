/* eslint-disable react/no-unescaped-entities */
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import Slider from "react-slick";
import AllReviews from "./AllReviews";
import Ratings from "./Ratings";
import reviews from "./data/reviews";

export interface Review {
  feedback: string;
  name: string;
  image?: string;
  title?: string;
}

const Reviews = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up("xs"), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  let slidesToShow = 1;
  if (isXs) {
    slidesToShow = 1;
  }
  if (isSm) {
    slidesToShow = 2;
  }
  if (isMd) {
    slidesToShow = 3;
  }
  if (isLg) {
    slidesToShow = 4;
  }
  const [open, setOpen] = useState(false);

  const sliderOpts = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return (
    <Box>
      <Container>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullScreen={!isMd}
          maxWidth="lg"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle
            id="dialog-title"
            sx={{
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2" component="h2" sx={{
                fontSize: 24,
                fontWeight: 700
              }}>
                Customer Reviews
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              id="dialog-description"
            >
              Read great reviews from our happy customers
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ background: "#F3F6FF", paddingTop: "20px" }}>
            <AllReviews />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Box textAlign="center" mt={4}>
          <Typography
            component="h2"
            variant="h4"
            fontWeight="bold"
            color="secondary"
            gutterBottom
          >
            Reviews
          </Typography>
          <Typography variant="h5" fontWeight="700">
            See what our amazing clients have to say about the work we do
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mb={4}>
          <Ratings />
        </Box>
      </Container>
      <Box mb={2}>
        <Slider {...sliderOpts}>
          {reviews.map((item, i) => (
            <Box
              px={{ xs: 1, md: 2, lg: 3 }}
              pb={{ xs: 1, md: 2, lg: 3 }}
              key={i}
            >
              <Card
                boxShadow={{ xs: 1, sm: 3 }}
                borderRadius={5}
                elevation={2}
                component={Box}
              >
                <CardContent
                  sx={{
                    backgroundColor: "#FEFEFE",
                    padding: 2,
                    height: 400,
                  }}
                >
                  <Typography
                    variant="body1"
                    color={theme.palette.text.primary}
                    fontSize={18}
                    fontWeight={500}
                    lineHeight={1.7}
                    mb={2}
                  >
                    {item.feedback}
                  </Typography>
                  <Box>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={`-${item.name}`}
                        primaryTypographyProps={{
                          variant: "subtitle1",
                          color: "gray",
                        }}
                      />
                    </ListItem>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Button size="large" variant="contained" onClick={() => setOpen(true)}>
          View all
        </Button>
      </Box>
    </Box>
  );
};

export default Reviews;
