import { Box, Rating } from "@mui/material";

const Ratings = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      marginTop={{ xs: 2, sm: 4 }}
      width="100%"
      justifyContent={{ xs: "space-evenly", md: "center" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginRight={{ xs: 0, md: 5 }}
        marginBottom={{ xs: 2, md: 0 }}
      >
        <Rating
          name="yelp-rating"
          value={4.7}
          precision={0.1}
          max={5}
          readOnly
          aria-label="Yelp rating: 4.7 out of 5"
          sx={{ marginBottom: 1 }}
        />
        <img
          height={50}
          src="https://broadly.com/wp-content/uploads/2018/05/Yelp_Logo.svg.png"
          alt="Yelp logo"
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginRight={{ xs: 0, md: 5 }}
        marginBottom={{ xs: 2, md: 0 }}
      >
        <Rating
          name="google-rating"
          value={4.5}
          readOnly
          aria-label="Google rating: 4.5 out of 5"
          sx={{ marginBottom: 1 }}
          precision={0.1}
        />
        <img height={50} src="/goog.png" alt="Google logo" />
      </Box>
    </Box>
  );
};

export default Ratings;
