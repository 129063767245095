"use client";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import shadows from "./shadows";
import palette from "./palette";

export type ThemeMode = "light" | "dark";
export type PaletteType = "blue" | "green" | "indigo" | "pink" | "orange";

const getTheme = (mode: ThemeMode, paletteType: PaletteType) =>
  responsiveFontSizes(
    createTheme({
      palette: palette(mode, paletteType),
      
    //   layout: {
    //     contentWidth: 1236,
    //   },
      shadows: shadows(mode),
      typography: {
        fontFamily: '"Inter", sans-serif',
        button: {
          textTransform: "none",
          fontWeight: "medium",
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
              fontWeight: 600,
            },
            containedSecondary: mode === "light" ? { color: "white" } : {},
          },
        },
      },
    })
  );

export default getTheme;
