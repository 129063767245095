import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Form from "./Form";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";

const Contact = () => {
  const theme = useTheme();

  const GridItemHeadlineBlock = () => (
    <Box>
      <Typography
        variant="h2"
        align="left"
        gutterBottom
        sx={{
          color: theme.palette.common.white,
          fontWeight: 900,
        }}
      >
        Get in touch!
      </Typography>
      <Box marginBottom={4}>
        <Typography
          variant="body1"
          component="p"
          sx={{
            color: theme.palette.common.white,
            fontWeight: 400,
          }}
        >
          Ready to discuss your vision? Reach out to us today via email, phone,
          or leave a message on our website. We're always delighted to offer a
          free consultation and assist with any inquiries you may have.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <PhoneIcon
              sx={{ color: theme.palette.common.white, marginRight: 1 }}
              fontSize="small"
            />
            <Typography
              // variant="h3"
              // component="h3"
              gutterBottom
              sx={{
                color: theme.palette.common.white,
                fontWeight: 400,
              }}
            >
              Call us:
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.common.white,
                fontWeight: 700,
              }}
              component="a"
              href="tel:8664663489"
            >
              866-GOOD-GUY
              <Typography
                variant="body1"
                component="span"
                sx={{
                  color: theme.palette.common.white,
                  fontWeight: 400,
                }}
              >
                (866-466-3489)
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <MailIcon
              sx={{ color: theme.palette.common.white, marginRight: 1 }}
              fontSize="small"
            />
            <Typography
              // variant="h3"
              // component="h3"
              gutterBottom
              sx={{
                color: theme.palette.common.white,
                fontWeight: 400,
              }}
            >
              Email us:
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              color: theme.palette.common.white,
              fontWeight: 700,
            }}
            component="a"
            href="mailto:info@goodguybuilders.com"
          >
            info@goodguybuilders.com
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const GridItemFormBlock = () => (
    <Box
      padding={{ xs: 3, sm: 6 }}
      width={"100%"}
      component={Card}
      borderRadius={2}
      boxShadow={4}
    >
      <Form />
    </Box>
  );

  return (
    <Box component="section">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <GridItemHeadlineBlock />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <GridItemFormBlock />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
