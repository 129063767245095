import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  Typography,
} from "@mui/material";
import ContactForm from "../Contact/Mini";
import Topbar from "../Topbar";

const Hero = () => {
  return (
    <>
      <AppBar
        component="header"
        position="relative"
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          zIndex: 2,
        }}
      >
        <Container>
          <Topbar />
        </Container>
      </AppBar>
      <Container>
        <Box
          component="section"
          display="flex"
          position="relative"
          zIndex={2}
          paddingTop={{ xs: 5 }}
          paddingBottom={{ xs: 4 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ xs: "center", md: "flex-start" }}
            flex="6"
          >
            <Typography
              component="h1"
              color="white"
              gutterBottom
              sx={{
                mb: 4,
                fontWeight: 700,
                fontSize: { xs: "2.1rem", md: "3rem" },
                lineHeight: 1.5,
              }}
              textAlign={{ xs: "center", md: "left" }}
            >
              Transform your home <br />
              with{" "}
              <Typography
                color="primary.light"
                component="span"
                sx={{
                  mb: 4,
                  fontWeight: 700,
                  fontSize: { xs: "2.1rem", md: "3rem" },
                }}
              >
                Good Guy Builders
              </Typography>
            </Typography>
            <Typography
              component="h2"
              variant="h5"
              color="white"
              gutterBottom
              sx={{
                mb: 4,
                fontWeight: 400,
                fontSize: { xs: "1.3rem", md: "1.5rem" },
                lineHeight: 1.5,
              }}
              textAlign={{ xs: "center", md: "left" }}
            >
              From kitchen and bathroom renovations to full home remodels and
              design builds.
            </Typography>

            <Typography
              component="h3"
              variant="h6"
              color="white"
              sx={{
                mb: 4,
                fontWeight: 400,
                fontSize: { xs: "1.2rem", md: "1.4rem" },
              }}
              textAlign={{ xs: "center", md: "left" }}
            >
              We bring your dream home to life.
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                component="a"
                variant="contained"
                sx={{
                  mb: { xs: 2, md: 0 },
                  mr: { xs: 0, md: 2 },
                  height: 54,
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  lineHeight: 1.2,
                  borderRadius: 2,
                }}
                href="tel:8664663489"
                onClick={(e) => {
                  // report conversion
                  if (typeof gtag_report_conversion === "function") {
                    gtag_report_conversion();
                  }
                }}
              >
                <Typography variant="body1" color="white">
                  Call Us
                </Typography>
              </Button>
              <Box textAlign={{ xs: "center", md: "left" }}>
                <Typography
                  color="white"
                  sx={{ fontWeight: 700, mb: 0.5 }}
                  variant="h6"
                >
                  866-GOOD-GUY
                </Typography>
                <Typography
                  color="white"
                  sx={{ fontWeight: 400 }}
                  variant="h6"
                >
                  (866-466-3489)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Hidden mdDown>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex="4"
            >
              <ContactForm />
            </Box>
          </Hidden>
        </Box>
      </Container>
    </>
  );
};

export default Hero;
