"use client";

import { PaletteOptions } from "@mui/material";

export const light: PaletteOptions = {
  //   alternate: {
  //     main: "#f7f9fc",
  //     dark: "#edf1f7",
  //   },
  //   cardShadow: "rgba(23, 70, 161, .11)",
  mode: "light",
  primary: {
    main: "#1976d2",
    light: "#2196f3",
    dark: "#0d47a1",
    contrastText: "#fff",
  },
  secondary: {
    main: "#00AB55",
    light: "#33CC70",
    dark: "#009357",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: "#2d3748",
    secondary: "#646e73",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#fff",
    default: "#fff",
    // level2: "#f5f5f5",
    // level1: "#fff",
  },
};

export const dark: PaletteOptions = {
  //   alternate: {
  //     main: "#1a2138",
  //     dark: "#151a30",
  //   },
  //   cardShadow: "rgba(0, 0, 0, .11)",
  common: {
    black: "#000",
    white: "#fff",
  },
  mode: "dark",
  primary: {
    main: "#1976d2",
    light: "#2196f3",
    dark: "#0d47a1",
    contrastText: "#fff",
  },
  secondary: {
    light: "#FFEA41",
    main: "#FFE102",
    dark: "#DBBE01",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: "#EEEEEF",
    secondary: "#AEB0B4",
  },
  divider: "rgba(255, 255, 255, 0.12)",
  background: {
    paper: "#222B45",
    default: "#222B45",
    // level2: "#333",
    // level1: "#2D3748",
  },
};
