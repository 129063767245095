import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem',
                backgroundColor: '#222B45',
                bottom: 0,
                width: '100%',
            }}
        >
            <Typography variant="body2" color="white">
                ©2024 by Good Guy Builders
            </Typography>
        </Box>
    );
};

export default Footer;
