import MenuIcon from "@mui/icons-material/Menu";
import { Button, Hidden, Link, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React from "react";
import NavDrawer from "../Hero/NavDrawer";

interface TopbarProps {}

const linkItems = [
  { name: "Services", href: "/#services" },
  { name: "About Us", href: "/#about" },
  { name: "Reviews", href: "/#reviews" },
  { name: "Contact Us", href: "/#contact" },
];

const Topbar: React.FC<TopbarProps> = () => {
  const theme = useTheme();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Box
      component="header"
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
      paddingY={2}
    >
      <NavDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
      <Box display={"flex"} alignItems={"center"}>
        <IconButton
          aria-label="Open navigation menu"
          sx={{
            display: { md: "none" },
            color: theme.palette.common.white,
            marginRight: 1,
          }}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Box
          component="a"
          href="/"
          title="GGB"
          display={"flex"}
          alignItems="center"
          sx={{ textDecoration: "none" }}
        >
          <img
            src="/logo-no-background.png"
            alt="Good Guy Builders Logo"
            width={90}
            height={"100%"}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: theme.shape.borderRadius,
            }}
          />
        </Box>
      </Box>
      <Box
        component="nav"
        sx={{ display: { xs: "none", md: "flex" } }}
        alignItems="center"
        flexDirection="row"
        flex={1}
        justifyContent="center"
      >
        {linkItems.map((item, index) => (
          <Box key={index} sx={{ mx: 2 }}>
            <Link
              underline="none"
              component="a"
              href={item.href}
              color="white"
              sx={{
                padding: "8px 16px",
                borderRadius: 1,
                fontWeight: 700,
                fontSize: "1.2rem",
              }}
            >
              {item.name}
            </Link>
          </Box>
        ))}
      </Box>
      <Hidden mdUp>
        <Box display="flex" alignItems={"center"}>
          <Box alignItems={"center"}>
            <Box>
              <Link href="/#contact" component="a">
                <Button variant="contained" color="primary">
                  Get a free quote
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
};

export default Topbar;
