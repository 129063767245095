import { colors } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider, { Settings } from "react-slick";

const Why = ({ themeMode = "light" }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const GridItemHeadlineBlock = () => (
    <Box position="relative">
      <Typography
        component="h2"
        fontWeight={700}
        variant="h4"
        data-aos={isMd ? "fade-right" : "fade-up"}
      >
        Why Work With Us?
      </Typography>
      <Typography
        component="h3"
        sx={{
          textTransform: "uppercase",
          fontWeight: "medium",
        }}
        gutterBottom
        color="secondary"
      >
        The Good Guy Promise
        <img
          src="https://www.shutterstock.com/image-vector/trademark-symbol-icon-260nw-317870552.jpg"
          alt="TradeMark Icon"
          width={20}
          height={20}
          style={{ marginLeft: 2 }}
        />
      </Typography>
    </Box>
  );

  const GridItemReviewBlock = () => {
    const sliderOpts: Settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 8000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const reviews = [
      {
        title: "Flexible Scoping",
        subtitle:
          "You are not locked into a scope of work. Add, remove or change anything at any time. You are in control. Always.",
        icon: (
          <svg
            width={48}
            height={48}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 10h16M4 14h16M4 18h16"
            />
          </svg>
        ),
        color: colors.amber,
      },
      {
        title: "Guaranteed dates",
        subtitle:
          "Our employees are all in-house. So we’ll be there when we say we’ll be there. From start to finish.",
        icon: (
          <svg
            width={48}
            height={48}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        ),
        color: colors.purple,
      },
      {
        title: "Radical Transparency",
        subtitle:
          "With daily and weekly project progress reports, you’ll never be in the dark about where your project stands.",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              d="M10 2C5.58 2 2 5.58 2 10s3.58 8 8 8c1.61 0 3.09-.49 4.33-1.31l4.71 4.71 1.41-1.41-4.71-4.71C17.51 13.09 18 11.61 18 10c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z"
              stroke="black"
              strokeWidth="2"
              fill="none"
            />
          </svg>
        ),
        color: colors.blueGrey,
      },
      {
        title: "Exceptional Quality",
        subtitle:
          "Our tradesmen are experts at their craft, and the quality of our work reflects their skill. We guarantee it.",
        icon: (
          <svg
            width={48}
            height={48}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
        ),
        color: colors.green,
      },
    ];

    return (
      <Box maxWidth="100%" data-aos={isMd ? "fade-left" : "fade-up"}>
        <Slider {...sliderOpts}>
          {reviews.map((item, i) => (
            <Box padding={{ xs: 1, sm: 2 }} key={i}>
              <Card
                component={Box}
                boxShadow={{ xs: 1, sm: 3 }}
                borderRadius={5}
                padding={{ xs: 1, sm: 2, md: 3 }}
                elevation={24}
              >
                <CardContent
                  component={Box}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    component={Avatar}
                    variant="rounded"
                    width={84}
                    height={84}
                    marginBottom={2}
                    bgcolor={
                      themeMode === "light" ? item.color[50] : item.color[200]
                    }
                    borderRadius={5}
                  >
                    <Box color={item.color[themeMode === "light" ? 500 : 700]}>
                      {item.icon}
                    </Box>
                  </Box>
                  <Typography variant="h6" gutterBottom fontWeight={700}>
                    {item.title}
                  </Typography>
                  <Typography color="text" fontWeight={400}>
                    {item.subtitle}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <GridItemHeadlineBlock />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <GridItemReviewBlock />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Why;
